import { v4 as uuidv4 } from 'uuid';
import { UilRefresh, UilEditAlt, UilSearch, UilChart } from '@iconscout/react-unicons';
// Images
import HeroEn from '../assets/products/blubinaCore/imgs/hero_en.png';
import HeroDe from '../assets/products/blubinaCore/imgs/hero_de.png';
// Videos
import VidLoadTable from '../assets/products/blubinaCore/vids/Blubina-Core_230524_load table_2.mp4';
import VidEditRecord from '../assets/products/blubinaCore/vids/Blubina-Core_230524_edit record.mp4';
import VidLookup from '../assets/products/blubinaCore/vids/Blubina-Core_230524_lookup_2.mp4';
import VidSelectTableRecord from '../assets/products/blubinaCore/vids/Blubina-Core_230527_select table and record.mp4';
import VidLeverageExcelFrontend from '../assets/products/blubinaCore/vids/Blubina-Core_230527_leverage Excel frontend for data analysis.mp4';
import VidManageMultiTables from '../assets/products/blubinaCore/vids/Blubina-Core_230527_manage multiple tables in context.mp4';
import VidBulkInsert from '../assets/products/blubinaCore/vids/Blubina-Core_230524_bulk insert.mp4';
import VidGenReport from '../assets/products/blubinaCore/vids/Blubina-Core_230522_gen report.mp4';
import VidManageStakeholders from '../assets/products/blubinaCore/vids/Blubina-Core_230528_manage stakeholder master data.mp4';
import VidManageContacts from '../assets/products/blubinaCore/vids/Blubina-Core_230528_manage contacts.mp4';
import VidCreateQuickMailing from '../assets/products/blubinaCore/vids/Blubina-Core_230528_create quick mailing.mp4';
import { contactLink } from '../utils/links';

export const BlubinaCoreProductDetails = {
    appTitle: 'product.blubinaCore.helmet.title',
    appDesc: 'product.blubinaCore.helmet.desc',
    dataName: 'BlubinaCore',
    productName: 'Blubina Core',
    heroDetails: {
        title: 'product.blubinaCore.main.title',
        subtitle: 'product.blubinaCore.main.subtitle',
        desc: 'product.blubinaCore.main.desc',
        img: {
            en: [HeroEn, HeroEn],
            de: [HeroDe, HeroDe],
            pl: [HeroEn, HeroEn],
            zh: [HeroEn, HeroEn],
        },
        firstBtnTxt: 'common.btn.learnMore',
        firstBtnAction: () => {
            const targetEl = document.getElementById('product-BlubinaCore__features');
            targetEl?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        secondBtnTxt: 'common.btn.requestDemo',
        secondBtnAction: () => (window.location = `mailto:${contactLink}`),
        learnMoreLink: 'product-BlubinaCore__features',
    },
    introWideVideo: null,
    intro: null,
    overview: null,
    explanationDetails: null,
    moreVideos: null,
    requirement: null,
    vidDetails: {
        actionArr: [
            {
                id: uuidv4(),
                idx: 1,
                txt: 'product.blubinaCore.video.loadTable',
                icon: <UilRefresh className="icon icon-load" />,
            },
            {
                id: uuidv4(),
                idx: 2,
                txt: 'product.blubinaCore.video.editRecord',
                icon: <UilEditAlt className="icon icon-edit" />,
            },
            {
                id: uuidv4(),
                idx: 3,
                txt: 'product.blubinaCore.video.lookUp',
                icon: <UilSearch className="icon icon-lookup" />,
            },
            {
                id: uuidv4(),
                idx: 4,
                txt: 'product.blubinaCore.video.genReport',
                icon: <UilChart className="icon icon-report" />,
            },
        ],
        vidArr: [
            { id: uuidv4(), idx: 1, videoSrc: VidLoadTable },
            { id: uuidv4(), idx: 2, videoSrc: VidEditRecord },
            { id: uuidv4(), idx: 3, videoSrc: VidLookup },
            { id: uuidv4(), idx: 4, videoSrc: VidGenReport },
        ],
    },
    pricingSection: {
        intro: 'product.blubinaCore.pricing.intro',
        sideIntro: 'product.blubinaCore.pricing.sideIntro',
        pricingArr: [
            {
                id: uuidv4(),
                idx: 1,
                category: 'basic',
                title: 'common.pricing.busineseBasic',
                price: {
                    title: 'product.blubinaCore.pricing.basicPrice',
                    unit: 'product.blubinaCore.pricing.basicPriceUnit',
                    method: 'product.blubinaCore.pricing.basicPriceMethod',
                },
                customPrice: null,
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.blubinaCore.pricing.basicPoint1',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.blubinaCore.pricing.basicPoint2',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.blubinaCore.pricing.basicPoint3',
                    },
                ],
                isFreeTrialBtn: false,
            },
            {
                id: uuidv4(),
                idx: 2,
                category: 'enterprise',
                title: 'common.pricing.busineseEnterprise',
                price: null,
                customPrice: 'product.blubinaCore.pricing.enterprisePrice',
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.blubinaCore.pricing.enterprisePoint1',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.blubinaCore.pricing.enterprisePoint2',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.blubinaCore.pricing.enterprisePoint3',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.blubinaCore.pricing.enterprisePoint4',
                    },
                ],
                isFreeTrialBtn: false,
            },
        ],
    },
    requestSection: {
        productName: 'Blubina Core',
        isReady: true,
        btnTxt: 'common.btn.requestDemo',
        btnAction: () => (window.location = `mailto:${contactLink}`),
    },
};

export const BlubinaCoreFeatureDetails = {
    moreBtnSection: {
        txt: 'product.blubinaCore.feature.introBetween',
        targetEl: 'product-BlubinaCore__feature-2',
    },
    intro: {
        section1: [
            { id: uuidv4(), txt: 'product.blubinaCore.feature.intro1-1' },
            { id: uuidv4(), txt: 'product.blubinaCore.feature.intro1-2' },
        ],
        section2: [{ id: uuidv4(), txt: 'product.blubinaCore.feature.intro2-1' }],
    },
    nav: {
        section1: [
            {
                id: uuidv4(),
                idx: '1-1',
                target: 'feature_professional-management-of-your-company-data',
                txt: 'product.blubinaCore.feature.point1-1Title',
            },
            {
                id: uuidv4(),
                idx: '1-2',
                target: 'feature_data-analysis-and-seamless-further-processing-thanks-to-excel',
                txt: 'product.blubinaCore.feature.point1-2Title',
            },
            {
                id: uuidv4(),
                idx: '1-3',
                target: 'feature_efficient-data-processing-and-information-linking',
                txt: 'product.blubinaCore.feature.point1-3Title',
            },
            {
                id: uuidv4(),
                idx: '1-4',
                target: 'feature_effortless-data-migration-and-import',
                txt: 'product.blubinaCore.feature.point1-4Title',
            },
            {
                id: uuidv4(),
                idx: '1-5',
                target: 'feature_powerful-reporting-module',
                txt: 'product.blubinaCore.feature.point1-5Title',
            },
            {
                id: uuidv4(),
                idx: '1-6',
                target: 'feature_data-integration-workflows-automation',
                txt: 'product.blubinaCore.feature.point1-6Title',
            },
        ],
        section2: [
            {
                id: uuidv4(),
                idx: '2-1',
                target: 'feature_stakeholder-data-management',
                txt: 'product.blubinaCore.feature.point2-1Title',
            },
            {
                id: uuidv4(),
                idx: '2-2',
                target: 'feature_contacts-and-mailing-lists',
                txt: 'product.blubinaCore.feature.point2-2Title',
            },
            {
                id: uuidv4(),
                idx: '2-3',
                target: 'feature_efficient-mailing-module-with-personalization',
                txt: 'product.blubinaCore.feature.point2-3Title',
            },
            {
                id: uuidv4(),
                idx: '2-4',
                target: 'feature_financial-accounting',
                txt: 'product.blubinaCore.feature.point2-4Title',
            },
            {
                id: uuidv4(),
                idx: '2-5',
                target: 'feature_participation-and-contract-management',
                txt: 'product.blubinaCore.feature.point2-5Title',
            },
            {
                id: uuidv4(),
                idx: '2-6',
                target: 'feature_portfolio-reporting',
                txt: 'product.blubinaCore.feature.point2-6Title',
            },
        ],
    },
    content: {
        section1: [
            {
                id: 'feature_professional-management-of-your-company-data',
                idx: '1-1',
                title: 'product.blubinaCore.feature.point1-1Title',
                desc: 'product.blubinaCore.feature.point1-1Desc',
                img: null,
                alt: null,
                video: VidSelectTableRecord,
                videoDesc: 'Blubina-Core_select table and record',
            },
            {
                id: 'feature_data-analysis-and-seamless-further-processing-thanks-to-excel',
                idx: '1-2',
                title: 'product.blubinaCore.feature.point1-2Title',
                desc: 'product.blubinaCore.feature.point1-2Desc',
                img: null,
                alt: null,
                video: VidLeverageExcelFrontend,
                videoDesc: 'Blubina-Core_leverage Excel frontend for data analysis',
            },
            {
                id: 'feature_efficient-data-processing-and-information-linking',
                idx: '1-3',
                title: 'product.blubinaCore.feature.point1-3Title',
                desc: 'product.blubinaCore.feature.point1-3Desc',
                img: null,
                alt: null,
                video: VidManageMultiTables,
                videoDesc: 'Blubina-Core_manage multiple tables in context',
            },
            {
                id: 'feature_effortless-data-migration-and-import',
                idx: '1-4',
                title: 'product.blubinaCore.feature.point1-4Title',
                desc: 'product.blubinaCore.feature.point1-4Desc',
                img: null,
                alt: null,
                video: VidBulkInsert,
                videoDesc: 'Blubina-Core_bulk insert',
            },
            {
                id: 'feature_powerful-reporting-module',
                idx: '1-5',
                title: 'product.blubinaCore.feature.point1-5Title',
                desc: 'product.blubinaCore.feature.point1-5Desc',
                img: null,
                alt: null,
                video: VidGenReport,
                videoDesc: 'Blubina-Core_generate report',
            },
            {
                id: 'feature_data-integration-workflows-automation',
                idx: '1-6',
                title: 'product.blubinaCore.feature.point1-6Title',
                desc: 'product.blubinaCore.feature.point1-6Desc',
                img: null,
                alt: null,
                video: null,
                videoDesc: null,
            },
        ],
        section2: [
            {
                id: 'feature_stakeholder-data-management',
                idx: '2-1',
                title: 'product.blubinaCore.feature.point2-1Title',
                desc: 'product.blubinaCore.feature.point2-1Desc',
                img: null,
                alt: null,
                video: VidManageStakeholders,
                videoDesc: 'Blubina Core_manage stakeholder master data',
            },
            {
                id: 'feature_contacts-and-mailing-lists',
                idx: '2-2',
                title: 'product.blubinaCore.feature.point2-2Title',
                desc: 'product.blubinaCore.feature.point2-2Desc',
                img: null,
                alt: null,
                video: VidManageContacts,
                videoDesc: 'Blubina Core_manage contacts',
            },
            {
                id: 'feature_efficient-mailing-module-with-personalization',
                idx: '2-3',
                title: 'product.blubinaCore.feature.point2-3Title',
                desc: 'product.blubinaCore.feature.point2-3Desc',
                img: null,
                alt: null,
                video: VidCreateQuickMailing,
                videoDesc: 'Blubina Core_create quick mailing',
            },
            {
                id: 'feature_financial-accounting',
                idx: '2-4',
                title: 'product.blubinaCore.feature.point2-4Title',
                desc: 'product.blubinaCore.feature.point2-4Desc',
                img: null,
                alt: null,
                video: null,
                videoDesc: null,
            },
            {
                id: 'feature_participation-and-contract-management',
                idx: '2-5',
                title: 'product.blubinaCore.feature.point2-5Title',
                desc: 'product.blubinaCore.feature.point2-5Desc',
                img: null,
                alt: null,
                video: null,
                videoDesc: null,
            },
            {
                id: 'feature_portfolio-reporting',
                idx: '2-6',
                title: 'product.blubinaCore.feature.point2-6Title',
                desc: 'product.blubinaCore.feature.point2-6Desc',
                img: null,
                alt: null,
                video: null,
                videoDesc: null,
            },
        ],
    },
};
